<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='Committee LCC' />
      <div>
      
      <div>
        <section class='flex flex-col lg:flex-row justify-between items-center gap-y-8 lg:gap-x-2 mb-16'>
          <div class='flex-shrink-0 flex-grow-0 w-full lg:w-1/2'>
            <h2 class='flex flex-row items-center justify-start gap-x-2 text-2xl mb-2 font-semibold text-gray-900'>
              <img src='https://apsr2022.org/img/sub/titBul.png' class='block h-4' />
              Honorary President
            </h2>
              <div class='flex flex-row justify-start items-center rounded-lg py-6 px-4 gap-x-2' style='background-color: #ffe5e9;'>
                <img src="https://apsr2022.org/img/sub/committeePresidentImg1.png" class='h-32'>
                <div class=''>
                  <h3 class='text-xl lg:text-2xl font-bold text-gray-900 mt-2'>Young Whan Kim</h3>
                  <h4 class='text-sm'>Konkuk University School of Medicine</h4>
                </div>
              </div>
          </div>
          <div class='flex-shrink-0 flex-grow-0 w-full lg:w-1/2'>
            <h2 class='flex flex-row items-center justify-start gap-x-2 text-2xl mb-2 font-semibold text-gray-900'>
              <img src='https://apsr2022.org/img/sub/titBul.png' class='block h-4' />
              President
            </h2>
              <div class='flex flex-row justify-start items-center rounded-lg py-6 px-4 gap-x-2' style='background-color: #e5efff;'>
                <img src="https://apsr2022.org/img/sub/committeePresidentImg2.png" class='h-32'>
                <div class=''>
                  <h3 class='text-xl lg:text-2xl font-bold text-gray-900 mt-2'>Ki-Suck Jung</h3>
                  <h4 class='text-sm'>Hallym University College of Medicine</h4>
                </div>
              </div>
          </div>
        </section>

        <section class='mb-8'>
          <h2 class=' text-2xl mb-2 font-semibold text-gray-900'>Congress Advisors</h2>
          <div class='flex flex-col lg:flex-row justify-start items-center rounded-lg py-6 px-4 gap-y-8 lg:gap-x-2 border'>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/parkinwon.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>In-Won Park</h3>
                <h4 class='text-sm leading-4'>Chung-Ang University College of Medicine</h4>
              </div>
            </div>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/yoochulgyu.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>Chul-Gyu Yoo</h3>
                <h4 class='text-sm leading-4'>Seoul National University College of Medicine</h4>
              </div>
            </div>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/kimyoungkyoon.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>Young Kyoon Kim</h3>
                <h4 class='text-sm leading-4'>The Catholic University of Korea College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>

        <section class='mb-16'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Advisor Committee</h2>
          <table class='text-normal w-full'>
            <tbody>
              <tr class='border-t'>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Joon Chang</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Kye Young Lee</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Konkuk University School of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Kwang Ho In</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hojoong Kim</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Sungkyunkwan University School of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Choon-Taek Lee</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Suk Joong Yong</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Kwan Hyoung Kim</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hee Jin Kim</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korean National Tuberculosis Association</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Younsuck Koh</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Myung Goo Lee</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>O Jung Kwon</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Sungkyunkwan University School of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yong Chul Lee</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chonbuk National University Medical School</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sang-Do Lee</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jung Hyun Chang</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Ewha Womans University College of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ju-Ock Kim</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chungnam National University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ho Kee Yum</th>
                <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inje University College of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Soo-Taek Uh</th>
                <td class='border-b border-gray-200 pr-2'>Soon Chun Hyang University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>TaeWon Jang</th>
                <td class='border-b border-gray-200 pr-2'>Kosin University College of Medicine</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jin-Hong Chung</th>
                <td class='border-b border-gray-200 pr-2'>Yeungnam University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Young-Chul Kim</th>
                <td class='border-b border-gray-200 pr-2'>Chonnam National University Medical School</td>
              </tr>
              <tr>
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ho Joo Yoon</th>
                <td class='border-b border-gray-200 pr-2'>Hanyang University College of Medicine</td>
              </tr><tr> <!--try one row-->
                <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Min Ki Lee</th>
                <td class='border-b border-gray-200 pr-2'>Pusan National University School of Medicine</td>
              </tr>
            </tbody>
          </table>
        </section>
               
        <section class='mb-8'>
          <h2 class=' text-2xl mb-2 font-semibold text-gray-900'>Secretary General</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full lg:w-1/2'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/shimjaejeong.png" class='h-28'>
              <div class=''>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Jae Jeong Shim</h3>
                <h4 class='text-sm'>Korea University College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>

        <section class='mb-16'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Vice Secretary Generals</h2>
          <div class='flex flex-col lg:flex-row justify-start items-center rounded-lg py-6 px-4 gap-y-8 lg:gap-x-2 border'>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/JangWonSohn.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>Jang-Won Sohn</h3>
                <h4 class='text-sm leading-4'>Hanyang University College of Medicine</h4>
              </div>
            </div>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/KwangHaYoo.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>Kwang Ha Yoo</h3>
                <h4 class='text-sm leading-4'>Konkuk University School of Medicine</h4>
              </div>
            </div>
            <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0 flex flex-row justify-start items-start gap-x-2'>
              <img src="https://apsr2022.org/img/sub/ChinKookRhee.png" class='h-24'>
              <div>
                <h3 class='font-semibold text-gray-900 text-lg mt-4'>Chin Kook Rhee</h3>
                <h4 class='text-sm leading-4'>The Catholic University of Korea College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>

        <div class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class=' text-xl mb-2 font-semibold text-gray-900'>Chair of Treasurer</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/kimdojin.png" class='h-28'>
              <div class=''>
                <h3 class='text-xl lg:text-2xl font-bold text-gray-900 mt-2'>Do-Jin Kim</h3>
                <h4 class='text-sm'>Soon Chun Hyang University College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>

        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Treasurer</h2>
          <table class='text-normal w-full'>
            <tbody>
              <tr class='border-t'>
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Kyung Hoon Min</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                </tr><tr> <!--try one row-->
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jaechun Lee</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Jeju National University School of Medicine</td>
              </tr>
              <tr>
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Young Sam Kim</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                </tr><tr> <!--try one row-->
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sang-Min Lee</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
              </tr>
              <tr>
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Seoung Ju Park</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chonbuk National University Medical School</td>
                </tr><tr> <!--try one row-->
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sang-Heon Kim</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hanyang University College of Medicine</td>
              </tr>
              <tr>
                  <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sang Haak Lee</th>
                  <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
              </tr>
            </tbody>
          </table>
        </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Planning Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/kimdeogkyeom.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Deog Kyeom Kim</h3>
                  <h4 class='text-sm'>Seoul National University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Planning</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jae Seung Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yoon-Seok Chang</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yeon-Joo Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Byung-Keun Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Registration Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/parkyongbum.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Yong Bum Park</h3>
                  <h4 class='text-sm'>Hallym University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Registration</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jae Chol Choi</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chung-Ang University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yousang Ko</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Chang Youl Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Tae-Hyung Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hanyang University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Exhibition Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/jangseunghun.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Seung Hun Jang</h3>
                  <h4 class='text-sm'>Hallym University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Exhibition</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Chang-Min Choi</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sunghoon Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Young Sik Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Eun Young Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Information Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/leejaeho.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Jae Ho Lee</h3>
                  <h4 class='text-sm'>Seoul National University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Information</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Young-Jae Cho</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>SeungYong Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chonbuk National University Medical School</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jinsoo Min</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sang Hoon Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>


        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>International Affairs Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/shimtaesun.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Tae Sun Shim</h3>
                  <h4 class='text-sm'>University of Ulsan College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of International Affairs</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jiwon Lyu</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Soon Chun Hyang University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Youjin Chang</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inje University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Joo hun Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Ajou University School of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jusang Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>External Affairs Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/parkmoosuk.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Moo Suk Park</h3>
                  <h4 class='text-sm'>Yonsei University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of External Affairs</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ji Ye Jung</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Won Jai Jung</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jin Woo Song</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jinkyeong Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Dongguk University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Governmental Affairs Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/limseongyong.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>SeongYong Lim</h3>
                  <h4 class='text-sm'>Sungkyunkwan University School of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Governmental Affairs</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jin Woo Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yang-Ki Kim</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Soon Chun Hyang University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Byoung Jun Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Veterans Health Service Medical Center</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Byoung-Hoon Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Eulji University School of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Tour Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/shinkyeongcheol.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Kyeong-Cheol Shin</h3>
                <h4 class='text-sm'>Yeungnam University College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Tour</h2>
          <table class=''>
              <tbody>
                <tr class='border-t'>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sun-Hyo Park</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Keimyung University School of Medicine</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hye Sook Choi</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Kyung Hee University School of Medicine</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hyun Kuk Kim</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inje University College of Medicine</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jong-Seo Yoon</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                </tr>
              </tbody>
          </table>
        </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Event Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/kimseungjoon.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Seung Joon Kim</h3>
                  <h4 class='text-sm'>The Catholic University of Korea College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Event</h2>
            <table class=''>
                <tbody>
                  <tr class='border-t'>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Somy Koo</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Soon Chun Hyang University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Seung Hyeun Lee</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Kyung Hee University School of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sue In Choi</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                  </tr>
                  <tr>
                      <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Dongwon Park</th>
                      <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hanyang University College of Medicine</td>
                  </tr>
                </tbody>
            </table>
          </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Conference Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/shinjongwook.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Jong Wook Shin</h3>
                  <h4 class='text-sm'>Chung-Ang University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Conference</h2>
            <table class=''>
              <tbody>
                <tr class='border-t'>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Chang Dong Yeo</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Chang Hoon Han</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>National Health Insurance Service Ilsan Hospital</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yon Ju Ryu</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Ewha Womans University College of Medicine</td>
                </tr>
                <tr>
                    <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jee Youn Oh</th>
                    <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Public Relations Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/parkmyungjae.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Myung Jae Park</h3>
                <h4 class='text-sm'>Kyung Hee University School of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Public Relations</h2>
              <table class=''>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jae Hwa Cho</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sung-Soon Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inje University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jinyoung Oh</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Dongguk University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Eun Joo Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>

        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
          <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Publication Board</h2>
            <div class='flex-shrink-0 flex-grow-0 w-full'>
              <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
                <img src="https://apsr2022.org/img/sub/kimjehyeong.png" class='h-28'>
                <div>
                  <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Je Hyeong Kim</h3>
                  <h4 class='text-sm'>Korea University College of Medicine</h4>
                </div>
              </div>
            </div>
          </section>
          <section class='flex-grow'>
            <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Publication</h2>
                <table class=''>
                    <tbody>
                      <tr class='border-t'>
                          <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Young Seok Lee</th>
                          <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                      </tr>
                      <tr>
                          <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Chi Ryang Chung</th>
                          <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Sungkyunkwan University School of Medicine</td>
                      </tr>
                      <tr>
                          <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sung Wook Kang</th>
                          <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Kyung Hee University School of Medicine</td>
                      </tr>
                      <tr>
                          <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jongmin Lee</th>
                          <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                      </tr>
                    </tbody>
                </table>
          </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Broadcast/Record Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/parkhyeyun.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Hye Yun Park</h3>
                <h4 class='text-sm'>Sungkyunkwan University School of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Broadcast/Record</h2>
              <table class=''>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hyun Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hanyang University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yong Suk Jo</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Youlim Kim</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Konkuk University School of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sun Hye Shin</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Sungkyunkwan University School of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Negotiation Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/leewonyeon.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Won-Yeon Lee</h3>
                <h4 class='text-sm'>Yonsei University Wonju College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Negotiation</h2>
              <table class=''>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>HyoungKyu Yoon</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sung-Woo Park</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Soon Chun Hyang University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sung-Ho Yoon</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chosun University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Gyu Young Hur</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Korea University College of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Ethics Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/leejinhwa.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Jin Hwa Lee</h3>
                <h4 class='text-sm'>Ewha Womans University College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Ethics</h2>
              <table class=''>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hyun-Kyung Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inje University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Yun Su Sim</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Hallym University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Su Hwan Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Yonsei University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Sojung Park</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Ewha Womans University College of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Medicolegal Affairs Board</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/limsungchul.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Sung Chul Lim</h3>
                <h4 class='text-sm'>Chonnam National University Medical School</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Medicolegal Affairs</h2>
              <table class=''>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>In-Jae Oh</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Chonnam National University Medical School</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Seong Hoon Yoon</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Pusan National University School of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ju Ock Na</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Soon Chun Hyang University College of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Cheol-Woo Kim</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Inha University School of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/2'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Auditor</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/ahnjoonghyun.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Joong Hyun Ahn</h3>
                <h4 class='text-sm'>The Catholic University of Korea College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/2'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Auditor</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/chungmanpyo.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Man Pyo Chung</h3>
                <h4 class='text-sm'>Sungkyunkwan University School of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        </div>
        <div  class='flex flex-col lg:flex-row justify-start items-start gap-y-4 lg:gap-y-0 lg:gap-x-8 mb-16'>
        <section class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Chair of Scientific Programme</h2>
          <div class='flex-shrink-0 flex-grow-0 w-full'>
            <div class='flex flex-row lg:flex-col justify-start lg:justify-center lg:text-center items-center rounded-lg py-6 px-4 gap-x-2 bg-gray-50 border border-gray-100'>
              <img src="https://apsr2022.org/img/sub/ohyeonmok.png" class='h-28'>
              <div>
                <h3 class='text-lg lg:text-xl font-bold text-gray-900 mt-2'>Yeon-Mok Oh</h3>
                <h4 class='text-sm'>University of Ulsan College of Medicine</h4>
              </div>
            </div>
          </div>
        </section>
        <section class='flex-grow'>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Committee of Scientific Programme</h2>
              <table class='w-full text-sm'>
                  <tbody>
                    <tr class='border-t'>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Soo-Jung Um</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Dong-A University College of Medicine</td>
                      </tr><tr> <!--try one row-->
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Ji-Hyun Lee</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>CHA University</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Hoil Yoon</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Seoul National University College of Medicine</td>
                      </tr><tr> <!--try one row-->
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Cheonwoong Choi</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>Kyung Hee University School of Medicine</td>
                    </tr>
                    <tr>
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Tae-Bum Kim</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>University of Ulsan College of Medicine</td>
                      </tr><tr> <!--try one row-->
                        <th class='border-b border-gray-200 font-semibold text-left whitespace-nowrap pr-4 py-2 align-text-top'>Jun-Pyo Myong</th>
                        <td class='border-b border-gray-200 pr-2 py-1 align-text-top'>The Catholic University of Korea College of Medicine</td>
                    </tr>
                  </tbody>
              </table>
            </section>
        </div>

        <div>
          <h2 class='text-lg mb-2 font-semibold text-gray-900'>Assembly Liaisons</h2>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Clinical Respiratory Medicine</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Ji-yong Moon</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Hanyang University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Asthma</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Sang Yeub Lee</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Korea University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Cell and Molecular Biology</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Sei Won Lee</h3>
                <h4 class='text-sm leading-4 text-gray-500'>University of Ulsan College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Respiratory Neurobiology and Sleep</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Hyeon Hui Kang</h3>
                <h4 class='text-sm leading-4 text-gray-500'>University of Ulsan College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Clinical Allergy &amp; Immunology</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Mi-Ae Kim</h3>
                <h4 class='text-sm leading-4 text-gray-500'>CHA University</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Critical Care Medicine</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Won-Il Choi</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Myongji Hospital</h4> <!--  2021-12-24 ���� <h4>Hanyang University College of Medicine</h4>-->
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Environmental &amp; Occupational Health and Epidemiology</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Woo Jin Kim</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Kangwon National University School of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Bronchoscopy and Interventional Techniques</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Bin Hwangbo</h3>
                <h4 class='text-sm leading-4 text-gray-500'>National Cancer Center</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Respiratory Infections (non-Tuberculosis)</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Kyung Soo Chung</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Yonsei University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Paediatric Lung Disease</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Dong In Suh</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Seoul National University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Tuberculosis</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Jae-Joon Yim</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Seoul National University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Respiratory Structure and Function</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Yong Il Hwang</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Hallym University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>COPD</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Seung Won Ra</h3>
                <h4 class='text-sm leading-4 text-gray-500'>University of Ulsan College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Pulmonary Circulation</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Yee Hyung Kim</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Kyung Hee University School of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Lung Cancer</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Sung Yong Lee</h3>
                <h4 class='text-sm leading-4 text-gray-500'>Korea University College of Medicine</h4>
              </div>
            </div>
            <div class='mb-2 border rounded-lg p-4 max-w-lg'>
              <div class='text-lg font-medium mb-2'>Interstitial Lung Disease</div>
              <div class='flex flex-row justify-between items-center gap-x-4'>
                <h3 class='font-semibold uppercase' :style='themeTextColorStyle'>Yong Hyun Kim</h3>
                <h4 class='text-sm leading-4 text-gray-500'>The Catholic University of Korea College of Medicine</h4>
              </div>
            </div>
        </div>

      </div>
    
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'CommitteeCcc',
  components: {
    Apsr2022AboutPageNavigation,
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },
}
</script>
